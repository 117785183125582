import HttpError from "standard-http-error"
import { IFormContext } from "./form"

let stripe // Stripe instance
let cardNumber // Stripe Elment
let token: string // PaymentIntent ID, "pi_XXXXXXXX"

interface Product {
  slug: string
  name: string
  price: number
  quantity: number
}
interface Intent {
  email: string
  amount: number
  lang: string
  products: Product[]
  updateState: (valid: boolean) => void
}

export const intent = async ({ email, amount, lang, products, updateState }: Intent) => {
  if (!stripe) {
    stripe = Stripe(process.env.GATSBY_STRIPE_KEY) // TODO: Stripe might not be loaded
  }

  const elements = stripe.elements({
    fonts: [
      {
        cssSrc: "https://fonts.googleapis.com/css?family=Questrial",
      },
    ],
  })

  const elementOptions = {
    style: {
      base: {
        fontFamily: "Questrial, sans-serif",
        fontSize: "16px",
      },
    },
  }

  const valid = {
    num: false,
    exp: false,
    cvc: false,
  }
  const update = () => {
    updateState(valid.num && valid.exp && valid.cvc)
  }

  cardNumber = elements.create("cardNumber", elementOptions)
  cardNumber.mount("#card_num")
  cardNumber.addEventListener("change", (event) => {
    valid.num = event.complete
    update()
  })

  const cardExpiry = elements.create("cardExpiry", elementOptions)
  cardExpiry.mount("#card_exp")
  cardExpiry.addEventListener("change", (event) => {
    valid.exp = event.complete
    update()
  })

  const cardCvc = elements.create("cardCvc", elementOptions)
  cardCvc.mount("#card_cvc")
  cardCvc.addEventListener("change", (event) => {
    valid.cvc = event.complete
    update()
  })

  const metadata = {
    _lang: lang,
  }
  products.forEach(({ name, quantity }) => {
    metadata[name] = quantity
  })

  const payload = {
    email,
    amount,
    metadata,
  }
  const response = await fetch("/.netlify/functions/intent", {
    method: "POST",
    body: JSON.stringify(payload),
  })
  if (response.status >= 400) {
    throw new HttpError(response.status, response.statusText) // TODO: handle
  }
  const data = await response.json()
  token = data.token
}

export const pay = async (values: IFormContext["values"]) => {
  const addressLines = values.address.split("\n")
  for (let i = 2; i < addressLines.length; i++) {
    addressLines[1] += ", " + addressLines[i]
  }
  const { error } = await stripe.handleCardPayment(token, cardNumber, {
    shipping: {
      name: values.name,
      address: {
        line1: addressLines[0],
        line2: addressLines[1],
        city: values.city,
        postal_code: values.postcode,
        country: values.countryName,
      },
      phone: values.tel,
    },
    receipt_email: values.email,
  })
  return error && error.message
}
