import React, { useEffect, useContext, useState } from "react"
import { navigate, graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../layout"
import { intent, pay } from "../helpers/stripe"
import { Message, Money, LangContext } from "../components/l10n"
import { CartContext } from "../components/cart"
import CartWidget from "../components/cartwidget"
import LockIcon from "../images/lock"
import { getCountryName } from "../helpers/countries"
import { IFormContext } from "../helpers/form"
import { Content, Title, buttonCss } from "../components/styled"
import { IProduct } from "../graph"

const Label = styled.label`
  display: block;
  margin: 20px 0;
  width: 400px;
  @media (max-width: 699px) {
    width: 100%;
  }
  @media (max-width: 399px) {
    font-size: 14px;
  }
`
const Row = styled.div`
  display: flex;
  margin: 20px 0;
  label {
    margin: 0;
    margin-right: 20px;
  }
  label:last-child {
    margin-right: 0;
  }
  @media (min-width: 700px) {
    width: 400px;
  }
`
const StripePlaceholder = styled.div`
  display: block;
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  border-radius: 20px;
  padding: 10px 15px;
  margin: 5px 0;
  width: 100%;
  min-height: 38px;
`
const PayButton = styled.button`
  ${buttonCss}
  margin-bottom: 40px;
  @media (max-width: 999px) {
    margin-bottom: 20px;
  }
`
const Security = styled.p`
  max-width: 400px;
  line-height: 20px;
  margin-bottom: 50px;
  @media (max-width: 399px) {
    font-size: 14px;
  }
`

const Input = ({ id, ...props }) => {
  return (
    <Label>
      <Message id={id} />
      <StripePlaceholder id={id} {...props} />
    </Label>
  )
}

const PayPage = ({ data, location }) => {
  const { cart, total } = useContext(CartContext)
  const { lang } = useContext(LangContext)

  const { domesticCountry, domesticCountryCode, shippingDomestic, shippingInternational } = data.site.siteMetadata

  const values: IFormContext["values"] = location.state

  const intl = values && values.country !== domesticCountryCode
  const shipping = intl ? shippingInternational : shippingDomestic

  const [valid, setValid] = useState(false)

  const handlePay = async () => {
    setValid(false)
    values.countryName = intl ? getCountryName(values.country) : domesticCountry
    const errors = await pay(values)
    if (errors) {
      alert(errors)
    } else {
      navigate("/thanks/", { replace: true })
    }
  }

  useEffect(() => {
    if (values) {
      if (cart.hydrated) {
        const subtotal = total()
        if (subtotal) {
          const products: IProduct[] = data.products.edges
            .map(({ node }) => node)
            .filter(({ node_locale }) => node_locale.indexOf(lang) === 0)

          intent({
            email: values.email,
            amount: 100 * (subtotal + shipping),
            lang,
            products: cart.products.map(({ slug, quantity }) => {
              const { name, price } = products.find((product) => product.slug === slug)
              return { slug, name, price, quantity }
            }),
            updateState: setValid,
          })
        } else {
          navigate("/cart/", { replace: true }) // empty cart
        }
      }
    } else {
      navigate("/checkout/", { replace: true })
    }
  }, [cart])

  return (
    <Layout noindex>
      <Content>
        <Title>
          <Message id="payment" />
        </Title>
        <Input id="card_num" />
        <Row>
          <Input id="card_exp" />
          <Input id="card_cvc" />
        </Row>
        <PayButton onClick={handlePay} disabled={!valid}>
          <LockIcon fill={valid ? null : "graytext"} /> <Message id="pay" /> <Money amount={total() + shipping} />
        </PayButton>
        <Security>
          <Message id="security" />
        </Security>
      </Content>
      <CartWidget intl={intl} />
    </Layout>
  )
}

export const query = graphql`
  query Pay {
    products: allContentfulProduct {
      edges {
        node {
          node_locale
          name
          slug
          price
        }
      }
    }
    site {
      siteMetadata {
        domesticCountry
        domesticCountryCode
        shippingDomestic
        shippingInternational
      }
    }
  }
`

export default PayPage
